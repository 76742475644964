@font-face {
  font-family: HelveticaNeueL;
  src: url("../fonts/HelveticaNeue_Light.ttf");
}

@font-face {
  font-family: HelveticaNeueBd;
  src: url("../fonts/HelveticaNeu_Bold.ttf");
}

@font-face {
  font-family: HelveticaNeueMed;
  src: url("../fonts/HelveticaNeueMed.ttf");
}

.text-bold {
  font-family: HelveticaNeueBd;
}

.text-med {
  font-family: HelveticaNeueMed;
}

body {
  font-family: HelveticaNeueL;
  color: #0D4C73;
  background-color: #FFF;
}

.border-1{
  border-bottom: 1px solid #E0E0E0;
}

.color-a{
  color:#0e5d8e !important;
  font-family: HelveticaNeueMed !important;
}

.footer {
 width: 100%;
 color: white;
 text-align: center;
}

.color-white{
  color:#FFF !important;
}

.footer {
background-color: #f5f5f5;
}

.footer > .container {
padding-right: 15px;
padding-left: 15px;
}

.feather {
width: 16px;
height: 16px;
vertical-align: text-bottom;
}

/*
* Sidebar
*/

.sidebar {
position: fixed;
top: 0;
bottom: 0;
width: 280px;
  min-width: 280px;
  max-width: 280px;
left: 0;
z-index: 1040; /* Behind the sidebar */
/*padding: 48px 0 0; /* Height of navbar */
box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
background-color: #004680 !important;
  transition: all 0.3s;
}

.sidebar-sticky {
position: relative !important;
top: 0;
height: calc(100vh - 48px);
padding-top: .5rem;
overflow-x: hidden;
overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
.sidebar-sticky {
  position: -webkit-sticky;
  position: sticky;
}
}

.active{
   border-bottom: 3px solid #BF343F;
}

.sidebar .nav-link {
font-weight: 500;
color: #FFF;
}

.sidebar .nav-link .feather {
margin-right: 4px;
color: #999;
}

.sidebar .nav-link.active {
  border-bottom: 3px solid #BF343F;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
color: inherit;
}

.sidebar-heading {
font-size: .75rem;
text-transform: uppercase;
}

/*
* Content
*/

[role="main"] {
padding-top: 133px; /* Space for fixed navbar */
}

@media (min-width: 768px) {
[role="main"] {
  padding-top: 48px; /* Space for fixed navbar */
}
}

/*
* Navbar
*/

.navbar-brand {
padding-top: .75rem;
padding-bottom: .75rem;
font-size: 1rem;
}

.navbar .form-control {
padding: .75rem 1rem;
border-width: 0;
border-radius: 0;
}

.form-control-dark {
color: #fff;
background-color: rgba(255, 255, 255, .1);
border-color: rgba(255, 255, 255, .1);
}

.form-control-dark:focus {
border-color: transparent;
box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}

.img-90p {
  width: 90%;
}

.ml-16{
  margin-left: 16px;
}

.input-group-success{
  background-color: #004680 !important;
  border: 1px solid #0D4C73 !important;
}

.frio-btn-toolbar {
  color: #0A4273 !important;
}

.frio-table-head{
  background-color: #F2F2F2;
}
.input-frio-caja{
  background-color: #F2F2F2;
}
.text-green{
  color: #5CB85C;
}

.br-danger{
border-color: #BF343F !important;
}

.br-success{
border-color: #0A4273 !important;
}

.vertical-scroll{
  overflow-y: auto !important;
}

.height-100{
  height: 100% !important;
}

/*    SCROLL DESIGN   */
/* width */
::-webkit-scrollbar {
width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
background: #f1f1f1;
border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
background: #718597;
border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
background: #555; 
}


.pl-menu{
  padding-left: 300px;
}

.pr-detalle{
  padding-right: 340px;
}
.fc-right .fc-prev-button, .fc-right .fc-next-button{
    background-color: #0A4273;
    background-image: none;
  }
  .fc-today-button, .btn-primary.disabled, .btn-primary:disabled{
    background: #BF364F;
    border-color: #BF364F ;
  }
  .fc-today{
    background: #E0E0E0;
  }
  