.label-alerta{
  font-weight: bold;
  font-size: 12px
}

.fade.modal-alert.show{
  top: 0;
  right: 17vw;
  padding: 0;
  display: flex;
  position: fixed;
  pointer-events: none;
  background-color: transparent;
  transition-property: background-color;
  width:390px
}
.fade.modal-alert{
  top: 0;
  right: -25vw;
  padding: 0;
  display: flex;
  position: fixed;
}
.label-check{
  color: #ffff;
  font-size: 12px
}

.maximizar {
float: right;
font-size: 1rem;
font-weight: 700;
line-height: 1.7;
color: #000;
text-shadow: 0 1px 0 #fff;
opacity: .5;
}

.maximizar:hover {
color: #000;
text-decoration: none
}


.maximizar:not(:disabled):not(.disabled):hover,
.maximizar:not(:disabled):not(.disabled):focus {
  opacity: .75
}

button.maximizar {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
  font-size: 1rem;
}


.fade.modal-posponer.show{
  top: 0;
  right: 40vw;
  padding: 0;
  display: flex;
  position: fixed;
}
.fade.modal-posponer{
  top: 0;
  right: -30vw;
  padding: 0;
  display: flex;
  position: fixed;
}

.ag-theme-alpine{
width: 100%; 
height: 680px !important; 
color:#131523;
}

.ag-theme-alpine .ag-header-row {
text-transform: uppercase;
font-family: 'montserratbold';
font-size: 12px;
justify-content: center !important;
text-align: center !important;
}

.ag-theme-alpine.paradas-inter{
width: 100%; 
height: 250px !important; 
color:#131523;
}

.ag-theme-alpine .ag-row {
font-family: 'worksans-regular';
padding-left: 5px !important;
padding-right: 2px !important;
}

.ag-theme-alpine .ag-header-row .ag-header-cell {
padding-left: 5px !important;
padding-right: 2px !important;
border: none;
justify-content: center !important;
text-align: center !important;
align-items: center;
}

.ag-header-group-cell-label, .ag-header-cell-label {
display: flex;
flex: 1 1 auto;
overflow: hidden;
align-items: center;
text-overflow: ellipsis;
justify-content: center !important;
text-align: center !important;
padding-left: 0px !important;
padding-right: 0px !important;
}


.ag-theme-alpine .ag-ltr .ag-cell {
border: none;
padding-left: 2px !important;
padding-right: 2px !important;
text-align: center !important;
}

.ag-theme-alpine .ag-row {
border: none !important;
border-color: transparent;
}

.ag-theme-alpine .ag-header {
background: #ececec;
border: none;
}

.ag-theme-alpine .ag-root-wrapper {
border: none;
}

.ag-paging-panel {
justify-content: center;
}

.ag-theme-alpine .ag-paging-panel {
border: none;
}

.toast-error {
  background-color: #BD362F;
}
.toast-info {
  background-color: #51A351;
}
.toast-warning {
  background-color: #FFCD02;
}
.modal-backdrop{
  position: relative;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100%;
  height: 100%;
  background-color: #000;
}

.fade.modal-tablero{
padding: 0;
display: flex;
position: fixed;
right: -10000vw;
}

.fade.modal-tablero.show{
position:fixed;
justify-content:center;
background-color: transparent;
transition-property: background-color;
display: flex;
align-items: center;
pointer-events: none;
top: 0;
left: 0;
width: 100%;
height: 100%;
z-index: 10010;
}

.fade.modal-tablero.show:hover{
z-index: 10100;
}


.modal-dialog.modal-lg.modal-dialog-centered{
background-color: transparent;
transition-property: background-color;
pointer-events: none;
}

.modal-dialog.modal-xl.modal-dialog-centered{
background-color: transparent;
transition-property: background-color;
pointer-events: none;
width: 100% !important;
max-height: 540px !important;
}

.modal-dialog.modal-xl{
background-color: transparent;
transition-property: background-color;
pointer-events: none;
width: 100% !important;
max-height: 540px !important;
}

.ag-theme-alpine.historicos{
width: 100%; 
height: 300px !important; 
color:#131523;
}

@media screen and (max-width: 1440px) and (min-width: 1026px){
.fade.modal-alert.show{
  top: 0;
  right: 17vw;
  padding: 0;
  display: flex;
  position: fixed;
  pointer-events: none;
  background-color: transparent;
  transition-property: background-color;
  width:290px;
  height: auto
}
.fade.modal-alert{
  top: 0;
  right: -25vw;
  padding: 0;
  display: flex;
  position: fixed;
} 
.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.15rem 0.2rem;
  font-size: .775rem;
  line-height: 1.0;
  border-radius: 0.2rem;
}
.ag-theme-alpine{
  width: 100%; 
  height: 600px !important; 
  color:#131523;
}

.ag-theme-alpine .ag-header-row {
  text-transform: uppercase;
  font-family: 'montserratbold';
  font-size: 10px;
}

.ag-theme-alpine .ag-row {
  font-family: 'worksans-regular';
  font-size: 10px !important;
  padding-left: 1.5px !important;
  padding-right: 1.5px !important;
}

.ag-theme-alpine .ag-header-row .ag-header-cell {
  padding-left: 1.5px !important;
  padding-right: 1.5px !important;
  border: none;
  justify-content: center !important;
  text-align: center !important;
  align-items: center;
}

.ag-header-group-cell-label, .ag-header-cell-label {
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  align-items: center;
  text-overflow: ellipsis;
  justify-content: center !important;
  text-align: center !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.ag-theme-alpine .ag-header {
  background: #ececec;
  border: none;

}

.ag-theme-alpine .ag-root-wrapper {
  border: none;
}

.ag-paging-panel {
  justify-content: center;
}

.ag-theme-alpine .ag-paging-panel {
  border: none;
}

.ag-theme-alpine .ag-ltr .ag-cell {
  padding-left: 5px !important;
  padding-right: 5px !important;
  border: none;
  text-align: center !important;
}

.cell-viaje{
  --width: 10px !important;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: small;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: #BF364F;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
  font-size: medium;
 }

 .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
  font-size: medium;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  font-family: HelveticaNeueMed;
  font-size: medium;
}
.modal-lg{
  max-height: 400px !important ;
  width: 100% !important;
}

.modal-xl{
  width: 100% !important;
  max-height: 540px !important;
}

}

@font-face {
font-family: 'worksans-regular';
src: url("../fonts/WorkSans-Regular.woff2") format('woff2');
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: 'montserratbold';
src: url('../fonts/montserrat-bold-webfont.woff2') format('woff2'),
     url('../fonts/montserrat-bold-webfont.woff') format('woff');
font-weight: normal;
font-style: normal;
}
.scrollable-menu {
height: auto;
max-height: 200px;
overflow-x: hidden;
}