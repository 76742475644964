@font-face {
    font-family: HelveticaNeueL;
    src: url("../fonts/HelveticaNeue_Light.ttf");
}

@font-face {
    font-family: HelveticaNeueBd;
    src: url("../fonts/HelveticaNeu_Bold.ttf");
}

.text-bold {
    font-family: HelveticaNeueBd;
}

body {
    font-family: HelveticaNeueL;
    color: #0D4C73;
    background-color: #FFF;
}

.footer {
    position: fixed !important;
    bottom: 0;
    left: 0;
   width: 100%;
   color: white;
   text-align: center;
}

.color-white{
    color:#FFF !important;
}

.margin-10{
    margin: 10px;
}

.margin-top-20{
    margin-top:20px;
}

.size-15p{
    width: 15%;
}

.size-26p{
    width: 26%;
}


main > .container {
  padding: 10px 0 10px 0;
}

.footer {
  background-color: #f5f5f5;
}

.footer > .container {
  padding-right: 15px;
  padding-left: 15px;
}

hr.solid {
    border-top: 1px solid #F2F2F2;
}

code {
  font-size: 80%;
}