/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
@import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
.multiselect-dropdown .dropdown-btn {
    display: inline-block !important;
    margin-bottom: 0 !important;
    font-weight: 400 !important;
    line-height: 1.1 !important;
    text-align: left !important;
    vertical-align: middle !important;
    cursor: pointer !important;
    background-image: none !important;
    border-radius: 4px !important;
    color: #0D4C73 !important;
    padding: .25rem .5rem !important;
    font-size: .875rem !important;
    line-height: 1.5 !important;
    border-radius: .2rem !important;
    border: 0px solid !important;
}

.dropdown-list{
    position: absolute !important;
    padding-top: 6px !important;
    width: auto !important;
    z-index: 9999 !important;
    border: 1px solid #E0E0E0 !important;
    border-radius: 3px !important;
    background: #ffffff !important;
    margin-top: 10px !important;
    box-shadow: 0 1px 5px #959595 !important;
    color: #0D4C73 !important;
}

span.dropdown-down{
    display: inline-block !important;
    top: 10px !important;
    width: 0 !important;
    height: 0 !important;
    border-top: 5px solid #0D4C73 !important;
    border-left: 5px solid transparent !important;
    border-right: 5px solid transparent !important;
    padding-bottom: 0.17rem !important;
    padding-left: 0.1rem !important;
    margin-left: 0.2rem !important;
}

span.dropdown-up{
    display: inline-block !important;
    top: 10px !important;
    width: 0 !important;
    height: 0 !important;
    border-bottom: 5px solid #0D4C73 !important;
    border-left: 5px solid transparent !important;
    border-right: 5px solid transparent !important;
    padding-bottom: 0.17rem !important;
    padding-left: 0.1rem !important;
    margin-left: 0.2rem !important;
    margin-bottom: 0.1rem !important;
}

.multiselect-item-checkbox > div::before{
    color: #BF343F !important;
    border: 2px solid #BF343F !important;
}

.multiselect-item-checkbox input[type=checkbox]:checked + div::before{
    color: #BF343F !important;
    background: #BF343F !important;
    border: 2px solid #BF343F !important;
}

.multiselect-item-checkbox input[type=checkbox] + div{
    color: #0A4273 !important;
}

.multiselect-dropdown .dropdown-btn .selected-item{
    border: 1px solid #BF343F !important;
    background: #BF343F !important;
}

.gj-picker-bootstrap table tr td.selected.gj-cursor-pointer div {
    color: #fff !important;
    background-color: #BF343F !important;
    border-color: #BF343F !important;
    border-radius: 4px !important;
}